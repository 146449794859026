// import { Modal } from 'bootstrap';
// import { Dropdown } from 'bootstrap';
// import { Collapse } from 'bootstrap';
// import { Alert } from 'bootstrap';
// import { Tooltip } from 'bootstrap';
// import CoreMailDecrypter from './decrypt_helper';

import {Splide} from '@splidejs/splide';
import GLightbox from 'glightbox';
import flatpickr from 'flatpickr';

import * as Klaro from 'klaro/dist/klaro-no-css';
import klaroConfig from './klaro_config';

// window.$ = window.jQuery = jQuery;

window.klaro = Klaro;
window.klaroConfig = klaroConfig;
Klaro.setup(klaroConfig);

const html = document.documentElement;
const body = document.body;

// console.log('hi');

// Handler when the DOM is fully loaded
function init() {


	// (new CoreMailDecrypter()).init();

	if (window.matchMedia('screen and (max-width: 768px)').matches) {
	} else {
		// videobox();
		// contactbox();
	}

	if( !document.body.classList.contains('type777') ) {

		// mobile menu show/hide
		let menubtn = document.getElementById('dl-menu');
		menubtn.addEventListener('click', function () {
			document.getElementById('dl-menu-btn').classList.toggle("active");
			html.classList.toggle("menu-open");
			body.classList.toggle("menu-open");
			document.getElementById('nav').classList.toggle("active");
		});

		// mobile menu enhancements
		// if(DEBUG) console.log('hi');
		const triggers = document.querySelectorAll('#nav ul > li span.trigger');
		const triggerItems = [].slice.call(triggers);
		triggerItems.forEach(function (el) {
			el.addEventListener('click', function (event) {
				event.preventDefault();
				if (el.classList.contains("active")) {
					el.classList.remove('active');
				} else {
					triggerItems.forEach(function (e) {
						e.classList.remove('active');
					});
					el.classList.add("active");
				}
			});
		});

		// sticky menu

		const fromtop = document.getElementsByTagName('header')[0].clientHeight;
		//console.log( fromtop );
		if (window.scrollY > fromtop && !body.classList.contains('sticky')) {
			body.classList.add('sticky');
		}
		window.addEventListener('scroll', function () {
			//console.log(window.scrollY);
			if (window.scrollY > fromtop && !body.classList.contains('sticky')) {
				body.classList.add('sticky');
			} else if (window.scrollY < fromtop) {
				body.classList.remove('sticky');
			}
		});

		window.addEventListener("resize", function () {
			// console.log('resize');
		});

		// prevent FOUC end
		// found here: https://www.abeautifulsite.net/a-clean-fade-in-effect-for-webpages
		body.classList.remove('fadein');


		let hasSlider = document.getElementsByClassName('slider');
		if (hasSlider.length > 0) {
			var splide = new Splide('.splide', {
				type: 'loop',
				autoplay: true
			});
			splide.mount();
		}

		let hasGcSlider = document.getElementsByClassName('gcslider');
		if (hasGcSlider.length > 0) {
			var splide = new Splide('.splide', {
				type: 'loop',
				perPage: 3,
				perMove: 1
			});
			splide.mount();
		}

		let customSlideHTML = `<div class="gslide">
	    <div class="gslide-inner-content">
	        <div class="ginner-container">
	            <div class="gslide-media">
	            </div>
	            <div class="gslide-description">
	                <div class="gdesc-inner">
	                    <h4 class="gslide-title"></h4>
	                    <div class="gslide-desc"></div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>`;

		let hasLightbox = document.getElementsByClassName('glightbox');
		if (hasLightbox.length > 0) {
			const lightbox = GLightbox({
				touchNavigation: true,
				loop: true,
				openEffect: 'fade',
				closeEffect: 'zoom',
				autoplayVideos: true,
				slideHTML: customSlideHTML
			});
		}
	}


}

if (document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll)) {
	init();
} else {
	document.addEventListener("DOMContentLoaded", init);
}



const showklaro = document.getElementsByClassName('js-showKlaro');
if (showklaro.length > 0) {
	showklaro.addEventListener("click", function (e) {
		e.preventDefault();
		klaro.show();
	});
}



/* EOF */
